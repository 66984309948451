/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: "manrope";
    src: url(./assets/manrope/Manrope-SemiBold.otf) format("opentype");
}

@font-face {
    font-family: "metropolis";
    src: url(./assets/metropolis/Metropolis-SemiBold.otf) format("opentype");
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    //font-family: Roboto, "Helvetica Neue", sans-serif;
    font-family: "metropolis", sans-serif !important;
}

$my-primary: mat.define-palette(mat.$light-blue-palette, 800);

$my-accent: mat.define-palette(mat.$deep-orange-palette, 500, 400, 600);

$my-theme: mat.define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
        ),
    )
);

@include mat.all-component-themes($my-theme);
